<template>
  <section class="container pb-8 cs-nav" data-style="3">
    <div class="row">
      <aside class="col-12 col-md-3 col-lg-2" id="filter-bar">
        <ul class="nav flex-column nav-pills d-none d-md-block" aria-orientation="vertical">
          <li
            v-for="item in QuestionCategorys"
            :key="item.qaCategoryId"
            :data-filter="item.qaCategoryId"
            :class="[
              'ul-li-block',
              'nav-link',
              'fa',
              { active: item.qaCategoryId === QuestionCategoryId },
            ]"
            @click="GetQuestionList(item.qaCategoryId, item.qaCategoryName)"
          >
            {{ item.qaCategoryName }}
          </li>
        </ul>
        <div class="dropdown cs-select mb-6 d-md-none" data-style="1">
          <button
            type="button"
            class="btn dropdown-toggle select__btn"
            id="dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-offset="10,20"
          >
            {{ QuestionCategoryName }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
            <li
              v-for="item in QuestionCategorys"
              :key="item.qaCategoryId"
              :data-filter="item.qaCategoryId"
              :class="['dropdown-item', 'select__option']"
              @click="GetQuestionList(item.qaCategoryId, item.qaCategoryName)"
            >
              {{ item.qaCategoryName }}
            </li>
          </div>
        </div>
      </aside>
      <div class="col-12 col-md-8 col-lg-9">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="QaList"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <ul class="cs-side" data-style="3">
              <li
                v-for="item in Questions"
                :key="item.qaId"
                :data-filter="item.qaId"
                class="side__item"
                v-on:click="ToggleClass($event)"
              >
                <div class="side__title fa">
                  {{ item.qaSubject }}
                </div>
                <div class="side__content">
                  <div
                    style="white-space: pre-wrap;max-height: 500px; overflow: auto;"
                    class="py-3"
                    v-html="item.qaContent"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetQuestionCategory, GetQuestionByCategoryId } from '@/api/QuestionApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      QuestionParam: {
        status: 1, // 將態為啟用
        qaCategoryId: 0, // 分類
      },
      Questions: [],
      QuestionCategorys: [],
      QuestionCategoryId: 0,
      QuestionCategoryName: '',
      ClickQa: '',
    };
  },
  created() {
    this.GetQuestionCategoryList();
  },
  mounted() {},
  methods: {
    GetQuestionCategoryList() {
      GetQuestionCategory().then((response) => {
        if (response.data.statusCode === 0) {
          this.QuestionCategorys = response.data.data;
          if (this.QuestionCategorys.length > 0) {
            this.GetQuestionList(
              this.QuestionCategorys[0].qaCategoryId,
              this.QuestionCategorys[0].qaCategoryName,
            );
          }
        }
      });
    },
    GetQuestionList(categoryId, categoryName) {
      this.QuestionParam.qaCategoryId = categoryId;
      this.QuestionCategoryId = categoryId;
      this.QuestionCategoryName = categoryName;
      GetQuestionByCategoryId(this.QuestionParam).then((response) => {
        if (response.data.statusCode === 0) {
          this.Questions = response.data.data;
          this.ClickQa = '';
        }
      });
    },
    ToggleClass(event) {
      if (event.target.className === 'side__title fa') {
        event.currentTarget.classList.toggle('active');
      }
    },
  },
};
</script>

<style>
.ul-li-block {
  display: block;
}
</style>
