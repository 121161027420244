import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/**
 * 取得常見問題分類
 * @param {object} data { }
 */
export const GetQuestionCategory = () => baseAxios.post('/FrontEnd/NovicesQaCategory/NovicesQaCategory');

/**
 * 前端-取得常見問題by分類
 * @param {object} data {
  "status": 0,
  "qaCategoryId": 0
  }
*/
export const GetQuestionByCategoryId = (data) => baseAxios.post('/FrontEnd/NovicesQa/NovicesQa', data);
